import { Module, VuexModule, VuexMutation } from 'nuxt-property-decorator'
import IImage from '../shared/general/interfaces/IImage'
import { ILandingPageHeaderElement } from '../shared/general/interfaces/ILandingPage'

@Module({
  namespaced: true,
  stateFactory: true,
})
export default class LandingPage extends VuexModule {
  logo2 : IImage | null = null

  logo3 : IImage | null = null

  campaignCta = {
    type: '',
    label: '',
    link: '',
  }

  @VuexMutation
  setState (data : ILandingPageHeaderElement) {
    this.logo2 = data.logo2 || null
    this.logo3 = data.logo3 || null
    this.campaignCta = data.campaignCta || {
      type: '',
      label: '',
      link: '',
    }
  }
}
